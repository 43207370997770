<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      :page_title="editLibraryData.topicName"
      @ShowAction="ShowAction"
    />
    <v-container fluid>
      <!-- New R10 UI -->
      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="12" md="4" lg="2" v-for="(item, i) in cardItems" :key="i">
          <!-- $router.push('/home/assignment-course/video-list') -->
          <!-- $router.push('/home/library/' + editLibraryData.topicName + '/' + item.name ) -->
          <v-card
            style="border-radius: 16px;"
            height="200"
            @click="
              item.title == $t('video') && $store.getters.showLibraryAction
                ?  gotoCourseVideoList()
                : $router.push({
                  path: '/home/library/' +
                      editLibraryData.topicName.split('?').join('') +
                      '/' +
                      item.name,
                  query: {topic_name : editLibraryData.topicName}
                })
            "
          >
            <v-card-text justify="center" align="center" style="height:100%">
              <div class="imgtext"></div>
              <v-img :src="item.image" width="52" class="communityimg"></v-img>
              <p class="text-center mt-5" style="font-size:18px;color:#424242">{{item.title}}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <cancel-save v-if="$store.getters.showLibraryAction" />
    </v-container>
    <SuccessDialog
      :show="successDialog1"
      :title="$t('success')"
      :text="message"
      @close="(successDialog1 = false), getData()"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import CancelSave from "../../../../components/cancelSave.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    //Edit,
    CancelSave
  },
  computed: {
    ...mapGetters(["editLibraryData"]),
    cardItems() {
      return [
        {
          image: require("@/assets/images/library_article.svg"),
          title: this.$t("article"),
          name: "Article"
        },
        {
          image: require("@/assets/images/library_sound.svg"),
          title: this.$t("sound"),
          name: "Sound"
        },
        {
          image: require("@/assets/images/library_video.svg"),
          title: this.$t("video"),
          name: "Video"
        }
      ];
    },
    breadcrumbs() {
      return [
        {
          //image: "house.png"
          sidebar_tilte: "home"
        },
        {
          text: "library",
          href: "/home/library"
        },
        {
          text: this.editLibraryData.topicName
        }
      ];
    }
  },
  data() {
    return {
      fullName_: "",
      //sortNumberItems: [],
      //sortNumberItems2: [],
      permissionDialog: false,
      permissionMessage: "",
      message: "",
      editDialog: false,
      successDialog1: false,
      editData: {}
    };
  },
  mounted() {
    this.getTopicWithId();
  },
  methods: {
    gotoCourseVideoList() {
      let breadcrumbs_temp = [
        {
          sidebar_tilte: "home"
        },
        {
          text: "library",
          href: "/home/library"
        },
        {
          text: this.editLibraryData.topicName
        },
        {
          text: "video"
        }
      ];
      this.$router.push("/home/assignment-course/video-list");
      localStorage.setItem(
        "pageTitle_forCourseVideoList",
        this.editLibraryData.topicName
      );
      localStorage.setItem(
        "breadcrumbs_forCourseVideoList", breadcrumbs_temp
      );
    },
    ShowAction(v) {
      if (v == "Edit") {
        this.checkTopicEdit();
      }
    },
    async checkTopicEdit() {
      let self = this;
      let checkPermission = await self.checkpermission(5);
      if (checkPermission) {
        self.editDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to edit Topic.";
      }
    },
    async getTopicWithId() {
      const res = await this.$axios.get(
        `${this.web_url}Topic/Topics/` + localStorage.getItem("libraryId")
      );
      this.editData = res.data.data;
      res.data.data["sortNumberItemLibrary"] = this.sortNumberItems;
      this.$store.commit("saveEditLibrary", res.data.data);
    }

    //for sorted dropdown number list..
    // async getTopic() {
    //   let self = this;
    //   const res = await this.$axios.get(
    //     `${this.web_url}Topic/GetTopics?id=` + localStorage.getItem("companyID")
    //   );
    //   var temp_null = [{ value: "" }];
    //   var temp_num = res.data.data.map((v, i) => {
    //     return { value: i + 1 };
    //   });
    //   self.sortNumberItems2 = temp_null.concat(temp_num);
    // }
  }
};
</script>

<style scoped lang="css">
.imgtext {
  height: 130px;
  background-color: #a6cc39;
  opacity: 0.1;
  border-radius: 8px;
  position: relative;
  z-index: 0 !important;
}
.communityimg {
  position: absolute;
  top: 40%;
  left: 48%;
  transform: translate(-50%, -48%);
}
.v-image__image {
  z-index: 10 !important;
}
</style>